import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MoreButton, Section } from 'src/components';
import { services } from 'src/utils/constants';
import Service from './Service';
import { ISectionProps } from 'src/utils/types';
import './ServicesSection.scss';

/**
 * Секция предоставляемых услуг.
 */

const ServicesSection: React.FC<Pick<ISectionProps, 'refCallback'>> = ({ refCallback }) => {
  const [open, setOpen] = useState(false);
  const [sectionWidth, setSectionWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const serviceListStyles = useMemo(() => {
    return open ? "services__list" : ["services__list","services__list_hide"].join(' ')
  }, [open]);


  useEffect(() => {
    setSectionWidth(containerRef.current?.getBoundingClientRect().width || 0)
  }, []);

  const sectionText = useMemo(() => {

    return sectionWidth > 361 
      ? '<< Компания QPD осуществляет полный цикл разработки от написания технического задания до внедрения и поддержки >> '
      : `<< Компания QPD осуществляет полный цикл разработки${'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'} от написания технического задания до внедрения и поддержки >>`
  }, [sectionWidth]);


  return (
    <Section 
      id="services"
      refCallback={refCallback}
      headerText='Что мы можем предложить'
      headerMessage={sectionText}
    >
      <div ref={containerRef} className={serviceListStyles}>
        {
          services.map((service) => {
            return (
              <Service
                key={service.header}
                header={service.header} 
                subHeader={service.subHeader} 
                styles={service.styles} 
              />
            )
          })
        }    
      </div>
      <MoreButton open={open} setOpen={setOpen}/>
    </Section>
  )
}
export default ServicesSection;