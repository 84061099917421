import React from 'react';

const MoreIcon:React.FC = () => {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="30.1023" y="17.7279" width="17.5" height="17.5" rx="8.75" transform="rotate(135 30.1023 17.7279)" stroke="#2E3333" strokeWidth="0.5"/>
      <path d="M20.8604 18.5778L17.603 21.8351L14.3456 18.5778" stroke="#2E3333" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.6031 12.6218L17.6031 21.835" stroke="#2E3333" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
export default MoreIcon;