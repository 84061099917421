import React from 'react';
import { IService } from 'src/utils/types';

const Service: React.FC<IService> = (props) => {

  return (
    <div className="service" style={props.styles}>
      <span className="service__header">{props.header}</span>
      <span className="service__subHeader">{props.subHeader}</span>
    </div> 
    )
  }

export default Service;
