import { IProject } from '../types';

 const projects: IProject[] = [
  {
    color: '#fff',
    backgroundColor: '#0861A7',
    name: 'ФНС РФ',
    description:  `Подсистема налогового мониторинга${'\u00A0\u00A0\u00A0\u00A0'} и аналитические инструменты контроля работы АИС "Налог 3" для нужд ФНС РФ`
  },
  {
    color: '#fff',
    backgroundColor: '#0F467B',
    name: 'АО Реестр',
    description: 'Информационная система "Удостоверение решения единственным акционером" для АО Реестр'
  },
  {
    color: '#fff',
    backgroundColor: '#0171B9',
    name: 'Газпром Нефть',
    description: `Информационная система "Управление рисками" и "Управление по целям"${'\u00A0\u00A0\u00A0\u00A0'} для ПАО “Газпром нефть”`
  },
  {
    color: '#2e3333',
    backgroundColor: '#FCCE20',
    name: 'Сибинтек',
    description: 'Система управления ИТ-сервисами Сибинтек'
  },
  {
    color: '#fff',
    backgroundColor: '#DB2101',
    name: 'Ашан',
    description: 'Система бюджетирования и подачи заявок на инвестиции для Ашан'
  },
  {
    color: '#fff',
    backgroundColor: '#092872',
    name: 'ВТБ',
    description: 'Информационно аналитическая система для Департамента Безопасности Банка ВТБ24'
  },
  {
    color: '#fff',
    backgroundColor: '#A61B16',
    name: 'РМСофт',
    description: 'Закрытая корпоративная система электронного документооборота PMSoft'
  },
  {
    color: '#fff',
    backgroundColor: '#B3002A',
    name: 'ПГК',
    description: 'Корпоративный портал Первой Грузовой Компании на платформе SharePoint 2016'
  },
  {
    color: '#fff',
    backgroundColor: '#00875D',
    name: 'СЗАО',
    description: 'Корпоративный портал Северо-Западного административного округа Москвы'
  }
]

export default projects;