import React, { PropsWithChildren } from 'react';
import { ISectionProps } from 'src/utils/types';
import { SectionHeader } from '../sectionHeader';

const Section:React.FC<PropsWithChildren<ISectionProps>> = ({ headerText, headerMessage, id, refCallback, children  }) => {
  return (
    <section className={id} id={id} ref={refCallback}>
      {headerText && <SectionHeader 
        headerText={headerText}
        headerMessage={headerMessage || ''}
      />}
      {children}
    </section>
  )
}
export default Section;
