import React from 'react';
import { Section } from 'src/components';
import { ISectionProps } from 'src/utils/types';
import './ContactsSection.scss';

/**
 * Секция наших контактов
 */

const ContactsSection:React.FC<Pick<ISectionProps, 'refCallback'>> = ({ refCallback }) => {
 
  return (
    <Section 
      id='contacts' 
      refCallback={refCallback}
      headerText='Наши контакты'
      >
      <div className="content">
        <div className="contacts__mobileCooperation">
            <span className="cooperation__header">Сотрудничество</span>
            <div className="cooperation__contacts">
              <div className="address__item">
                <span className="address__header">Телефон:</span>
                <a className="address__subHeader" href="tel:+79202123262">8 (920) 212 – 32 – 62</a>
              </div>
            <div className="address__item">
                <span className="address__header">Почта:</span>
                <a className="address__subHeader" href="mailto:Info@qpdev.ru">Info@qpdev.ru</a>
              </div>
            </div>
        </div>
        <div id="YMap" className="contacts__map" ></div>
        <div className="contacts__info">
            <address className="address">
                <div className="address__item">
                  <span className="address__header">г. Воронеж</span>
                  <span className="address__subHeader">ул. Революции 1905 года, 31Ю, пом. 1/4</span>
                </div>
                <div className="address__item">
                  <span className="address__header">Телефон:</span>
                  <a className="address__subHeader" href="tel:+79202123262">8 (920) 212 – 32 – 62</a>
                </div>
                <div className="address__item">
                  <span className="address__header">Почта:</span>
                  <a className="address__subHeader" href="mailto:Info@qpdev.ru">Info@qpdev.ru</a>
                </div>
            </address>
            <div className="contacts__cooperation">
              <span className="cooperation__header">Сотрудничество</span>
              <div className="cooperation__contacts">
                <div className="address__item">
                  <span className="address__header">Телефон:</span>
                  <a className="address__subHeader" href="tel:+79202123262">8 (920) 212 – 32 – 62</a>
                </div>
                <div className="address__item">
                  <span className="address__header">Почта:</span>
                  <a className="address__subHeader" href="mailto:Info@qpdev.ru">Info@qpdev.ru</a>
                </div>
              </div>
            </div>
        </div>
      </div>
    </Section>
  )
}

export default ContactsSection;