import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Section } from 'src/components';
import { ISectionProps } from 'src/utils/types';
import './AboutSection.scss';
import { CompaniesSlider } from './CompaniesSlider';

/**
 * Секция про компанию.
 */
const AboutSection:React.FC<Pick<ISectionProps, 'refCallback'>> = ({ refCallback }) => {
  const [sectionWidth, setSectionWidth] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSectionWidth(containerRef.current?.getBoundingClientRect().width || 0)
  }, []);

  const sectionText = useMemo(() => {

    return sectionWidth > 361 
      ? '<< Компания работает и развивается с 2011 года. За это время мы прошли большой путь развития наших компетенций и опыта. Мы постоянно совершенствуемся и никогда не останавливаемся на достигнутом  >> '
      : '<< Компания работает и развивается с 2011 года. >>'
  }, [sectionWidth]);
  
  return (
    <Section 
      id='about'
      refCallback={refCallback}
      headerText={`Развиваемся ${'\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'} и сотрудничаем`}
      headerMessage={sectionText}
    >
      <div ref={containerRef} className="about__timeLine">
        
        <div className="timeLine__upperDates">
          <div className="timeLine__firstElement">
            <span className="timeLine__date">2011</span>
            <div className="timeLine__text">
              <span className="text__header">август</span>
              <br/>
              <span className="text__subHeader">Наш первый проект <br/> <br/>Сделали проект для “Ашан”, поддерживали и развивали его восемь лет</span>
            </div>
          </div>
          <div className="timeLine__secondElement">
            <span className="timeLine__date">2021</span>
            <div className="timeLine__text">
              <span className="text__header">март</span>
              <br/><br />
              <span className="text__subHeader">Работаем с АО ГНИВЦ <br/>для ФНС РФ</span>
            </div>
          </div>
          <div className="timeLine__thirdElement">
            <span  className="timeLine__date">2023</span>
            <div className="timeLine__text">
              <span className="text__header">сентябрь</span>
              <br/><br />
              <span className="text__subHeader">Активно участвуем <br/> и выигрываем в тендерах</span>
            </div>
          </div>
        </div>
        <hr className="timeLine__line"/>

        <div className="timeLine__lowerDates">
          <div className="timeLine__firstElement">
            <span className="timeLine__date">2018</span>
            <div className="timeLine__leftText">
              <span className="text__header">февраль</span>
              <br/>
              <span className="text__subHeader">Получили аккредетацию ИТ-компании</span>
            </div>
            <div className="timeLine__rightText">
              <span className="text__header">март</span>
              <br/>
              <span className="text__subHeader">Сотрудничаем <br/> с ГазИнформСервис</span>
            </div>
          </div>
          <div className="timeLine__firstElementMobile">
            <span className="timeLine__date">2018</span>
            <div className="timeLine__text">
              <span className="text__header">февраль-март </span>
              <br/>
              <span className="text__subHeader">Получили аккредетацию ИТ-компании <br/> Начали сотрудничество с ГазИнформСервис</span>
            </div>
          </div>
          <div className="timeLine__secondElement">
            <span className="timeLine__date">2022</span>
            <div className="timeLine__text">
              <span className="text__header">январь</span>
              <br/><br />
              <span className="text__subHeader">Заехали в наш новый офис. Свой офиc :)</span>
            </div>
          </div>
        </div>
      </div>
      <CompaniesSlider />
    </Section>
  )
}

export default AboutSection;