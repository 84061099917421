import { IService } from '../types';
import serviceDevelopind from 'src/assets/img/serviceDeveloping.png';
import serviceTesting from 'src/assets/img/serviceTesting.png';
import serviceIntegration from 'src/assets/img/ServiceIntegration.png';

const services: IService[] = [
  {
    header: 'Заказная разработка',
    subHeader: 'Сделаем ваш бизнес более эффективным и конкурентоспособным',
    styles: {background: 'linear-gradient(147deg, rgb(147, 116, 185, 0.7) 28.96%, rgb(195, 108, 164, 0.7) 45.86%, rgb(85, 123, 187, 0.7) 66.57%)'}
  }, 
  {
    header: 'Разработка корпоративных веб-систем',
    subHeader: 'Объединим бизнес в единое информационное пространство',
    styles: {backgroundImage: `url(${serviceDevelopind})`}
  },
  {
    header: 'Автоматизация процессов предприятия',
    subHeader: `Автоматизируем стандартные${'\u00A0\u00A0\u00A0\u00A0\u00A0'} или специфичные бизнес-процессы`,
    styles: {background: 'linear-gradient(147deg, rgb(0, 121, 140, 0.7) 28.96%, rgb(45, 129, 183, 0.7) 39%, rgb(85, 123, 187, 0.7) 45.86%, rgb(170, 148, 197, 0.7) 66.57%)'}
  },
  {
    header: 'Системная интеграция',
    subHeader: 'Спроектируем, разработаем и внедрим интеграционные решения для проектов бизнеса любого масштаба ',
    styles: {backgroundImage: `url(${serviceIntegration})`}
  },
  {
    header: 'Аутсорсинг и аутстаффинг',
    subHeader: 'Усилим вашу команду нашими специалистами или полностью возьмем реализацию проекта на себя',
    styles: {background: 'linear-gradient(286deg, rgb(239, 178, 45, 0.7) 8.07%, rgb(0, 121, 140, 0.7) 40.49%, rgb(0, 114, 106, 0.7) 79.27%)'}
  },
  {
    header: 'Тестирование',
    subHeader: `Обеспечим контроль качества, ручное и автоматизированное тестирование на всех этапах разработки`,
    styles: {backgroundImage: `url(${serviceTesting})`}
  },
]

export default services;