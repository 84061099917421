import React, { useMemo, useRef, useState, useEffect } from 'react';
import Reestr from 'src/assets/svg/Reestr.svg';
import Nicotech from 'src/assets/svg/Nicotech.svg';
import GNIVC from 'src/assets/svg/GNIVC.svg';
import SoftConsult from 'src/assets/svg/SoftConsult.svg';
import ITSKIcon from 'src/assets/svg/ITSKIcon.svg';
import Dataglie from 'src/assets/svg/Dataglie.svg';
import JTC from 'src/assets/svg/JTC.svg';
import Amtex from 'src/assets/svg/Amtex.svg';

const companies = [ Dataglie, GNIVC, Reestr, ITSKIcon, Nicotech, SoftConsult, Amtex, JTC ];

export const CompaniesSlider:React.FC = () => {
  const [marqueeWidth, setMarqueeWidth] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setMarqueeWidth(containerRef.current?.getBoundingClientRect().width || 0); 
  }, []);

  // Длительность анимации
  const duration = useMemo(() => {
    return marqueeWidth / 35;
  }, [marqueeWidth]);

  const marqueeStyle = useMemo(
    () => ({
      ["--duration" as string]: `${duration}s`,
    }),
    [ duration]
  );

  return (
    <div className="companiesSlider">
      <div className="slider__list" style={marqueeStyle} ref={containerRef}>
          {companies.map((child, id) => {
            return (
              <img key={id} src={child} alt="Иконка компании" className="company__icon"/>
            );
          })}
  
      </div>
      <div className="slider__list" style={marqueeStyle}>
        {companies.map((child, id) => <img src={child} key={id} alt="Иконка компании" className="company__icon"/>)}
      </div>
    </div>
  )
}