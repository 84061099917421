import React from 'react';

const MobileRightArrow: React.FC = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="white"/>
      <path d="M17.5 13.5H26.5V22.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.772 26.2279L26.4999 13.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default MobileRightArrow;