import React from 'react';
import { vacancies } from 'src/utils/constants';
import Vacancy from './Vacancy';
import { RightArrow } from 'src/assets/svg';
import { ISectionProps } from 'src/utils/types';
import { Section } from 'src/components';
import './VacanciesSection.scss';
import MobileRightArrow from 'src/assets/svg/MobileRightArrow';

/**
 * Секция активных вакансий.
 */

const VacanciesSection:React.FC<Pick<ISectionProps, 'refCallback'>> = ({ refCallback }) => {
  
  return (
    <Section 
      id="vacancies" 
      refCallback={refCallback}
    >
      <div>
        <h2>Ищем коллег</h2>
        <div className="vacancies__list">
            {vacancies.map((vacancy) => 
              <Vacancy 
                key={vacancy.name} 
                name={vacancy.name} 
                experience={vacancy.experience} 
                salary={vacancy.salary} 
                skills={vacancy.skills} 
              />)}
        </div>
      </div>
      <div className="vacancies__content">
        <div className="vacancies__all">
          <span>Все вакансии</span>
          <a className="vacancies__link" target="_blank" href="https://voronezh.hh.ru/employer/1630774" rel="noreferrer">
            <RightArrow />
          </a>
          <a className="vacancies__mobileLink" target="_blank" href="https://voronezh.hh.ru/employer/1630774" rel="noreferrer">
            <MobileRightArrow />
          </a>
        </div>
        <div className="vacancies__office">
          <div className="office__img">
            <svg>
              <clipPath id="vacancies-clip-path" clipPathUnits="objectBoundingBox">
              <path d="M1,0.042 C1,0.019,0.98,0,0.956,0 H0.438 C0.414,0,0.394,0.019,0.394,0.042 V0.261 C0.394,0.285,0.374,0.304,0.35,0.304 H0.044 C0.02,0.304,0,0.322,0,0.346 V0.958 C0,0.981,0.02,1,0.044,1 H0.394 H0.542 H0.956 C0.98,1,1,0.981,1,0.958 V0.042"></path>
              </clipPath>
            </svg>
            <svg>
              <clipPath id="vacancies-tabletClip-path" clipPathUnits="objectBoundingBox">
                <path d="M0.617,1 L0.966,1 C0.985,1,1,0.972,1,0.938 L1,0.362 C1,0.328,0.985,0.3,0.966,0.3 L0.684,0.3 C0.666,0.3,0.65,0.273,0.65,0.239 L0.65,0.062 C0.65,0.028,0.635,0,0.617,0 L0.034,0 C0.015,0,0,0.028,0,0.062 V0.3 L0,0.938 V0.938 C0,0.972,0.015,1,0.034,1 V1 L0.617,1"></path>
              </clipPath>
            </svg>
            <svg viewBox="0 0 340 165" fill="none" xmlns="http://www.w3.org/2000/svg">
              <clipPath id="vacancies-mobileClip-path">
              <path fillRule="evenodd" clipRule="evenodd" d="M13 0C5.8203 0 0 5.8203 0 13V71V151V151.23C0 158.41 5.8203 164.23 13 164.23H327C334.18 164.23 340 158.41 340 151.23V71C340 63.8203 334.18 58 327 58H179C171.82 58 166 52.1797 166 45V13C166 5.8203 160.18 0 153 0H13Z" fill="#D9D9D9" fillOpacity="0.15"/>
              </clipPath>

            </svg>

          </div>
          
          <div className="vacancies__firstMessages">
            <span className="firstMessage">оформление по ТК РФ</span>
            <span className="secondMessage">обсуждаемая ЗП</span>
            <span className="thirdMessage">обучение за счет компании</span>
          </div>
          <div className="vacancies__secondMessages">
            <span className="firstMessage">ДМС</span>
            <span className="secondMessage">бесплатные обеды</span>
            <span className="thirdMessage">корпоративы</span>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default VacanciesSection;
