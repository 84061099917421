import { Footer, Wrapper, Header } from './components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { INavigationAncor } from './utils/types/navigationItem';
import { AboutSection, ContactsSection, MainSection, ProjectsSection, ServicesSection, TechnologiesSection, VacanciesSection } from './sections';

function App() {

  const [visibleSection, setVisibleSection] = useState<INavigationAncor>('main');
  const sectionsRef = useRef<HTMLElement[]>([]);

  useEffect(() => {
    const options = {
      threshold: 0.5
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisibleSection(entry.target.getAttribute("id") as INavigationAncor);
        }
      });
    }, options);

    sectionsRef.current.forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect();
    
  }, []);

  const refCallback = useCallback((element: HTMLElement) => {
    if (element) {
      sectionsRef.current.push(element);
    }
  }, [sectionsRef]);

  return (
    <div>
      <Header activePage={visibleSection} />
      <main>
        <Wrapper>
          <MainSection refCallback={refCallback} />
          <AboutSection refCallback={refCallback} />
          <ServicesSection refCallback={refCallback} />
          <TechnologiesSection refCallback={refCallback} />
          <ProjectsSection refCallback={refCallback} />
          <VacanciesSection refCallback={refCallback} />
          <ContactsSection refCallback={refCallback} />
        </Wrapper>
      </main>
      <Footer />
    </div>
  );
}

export default App;
