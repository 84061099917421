import { QPDIcon } from 'src/assets/svg';
import React, { useState, useMemo } from 'react';
import { navigation } from 'src/utils/constants';
import { IHeaderProps } from 'src/utils/types';
import './Header.scss';

/**
 * Хэдер страницы
 */
const Header: React.FC<IHeaderProps> = ({ activePage }) => {

  const [open, setOpen] = useState(false);

  const headerItemsStyle = useMemo(() => {
    return open ? "header__items" : "header__items header__items_close";
  }, [open])

  const burgerStyle = useMemo(() => {
    return open ? "burgerButton burgerButton__open" : "burgerButton";
  }, [open])

  return (
    <header className="header">
      <a href="#main"><QPDIcon /></a>
      <div className={headerItemsStyle}>
        {navigation.map((item) => {
          return (
            <a
              href={`#${item.anchor}`}
              key={item.name}
              onClick={() => setOpen(false)}
              className={activePage === item.anchor ? ["header__item","header__item_active"].join(' ') : "header__item"}
            >
                {item.name}
            </a>
          )
        })}
        <a href="#vacancies" onClick={() => setOpen(false)} className="header__button">
          Вакансии
        </a >
      </div>

      <div className={burgerStyle} onClick={() => setOpen(!open)}>
        <div className="icon"></div>
      </div>
    </header>
  )
}

export default Header;

